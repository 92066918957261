<template>
    <div class="content">
        <div class="content-warp">
            <crumbs :crumbs="crumbs" />
        </div>
        <div style="margin-top:40px">
          <el-form>
            <el-row>
              <el-col :span="6">
                <el-form-item label="商品编号" label-width="100px">
                  <el-input v-model="form.itemName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="商品主图" label-width="100px">
                  <img :src="form.mainImage" alt="" srcset="" style="width:200px;height:200px">
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="风格定位" label-width="100px">
                  <el-input v-model="goodsStyle"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="品类" label-width="100px">
                  <el-input v-model="goodsAgeGroup"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="成本价" label-width="100px">
                  <el-input v-model="form.supplyPrice"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="期货价" label-width="100px">
                  <el-input v-model="form.bookPrice"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="现货价" label-width="100px">
                  <el-input v-model="form.salesPrice"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="零售价" label-width="100px">
                  <el-input v-model="form.marketPrice"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="最短生产时间" label-width="100px">
                  <el-input v-model="form.constructionPeriod"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="最小单色起订量" label-width="100px">
                  <el-input v-model="form.minimumBookSingle"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="供应商" label-width="100px">
                  <el-input v-model="form.brandName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="供应商货号" label-width="100px">
                  <el-input v-model="form.supplyItemNo"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="年龄定位" label-width="100px">
                  <el-input v-model="goodsAgeGroup" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="格调指数" label-width="100px">
                  <el-input v-model="styleIndex"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="面料成份" label-width="100px">
                  <el-input v-model="fabricComposition"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="辅料成份" label-width="100px">
                  <el-input v-model="assistComposition"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
           
        </div>
        <div class="dis">
          <el-button type="primary" @click="editItemSimpleV2">修改</el-button>
        </div>
    </div>
</template>
<script>
 import crumbs from '../../components/public/crumbs.vue'
export default {
  components:{
    crumbs
  },
  data(){
    return{
      crumbs: [
            {text:'库存管理',path:'/stockManagement'},
            {text:'商品详情',path:'/stockInfo'},
        ],
        form:{},
        goodsStyle:'',
        goodsAgeGroup:'',
        styleIndex:'',
        clothingCategory:'',
        assistComposition:'',
        fabricComposition:'',
    }
  },
  created(){
    console.log(this.$route)
    this.itemId = this.$route.query.id
    this.getSkuAttrCfgByCategoryId()
  },
  methods:{
    // /itemShop/getSkuAttrCfgByCategoryId
     editItemSimpleV2(){
       
        let params = {
            itemId:this.form.itemId,
            minimumBookSingle:this.form.minimumBookSingle
        }
        this.$post('/itemShop/editItemSimpleByItemId',params).then(res =>{
            this.$message.success('修改最小起订量成功')
        })
    },
    getSkuAttrCfgByCategoryId(){
      let params = {
         itemId:this.itemId
      }
      this.$get('/itemManager/getItemDetaiByItemId',params).then(res =>{
        console.log(res)
        this.form = res.data
        this.form.supplyPrice = this.form.supplyPrice / 100
        this.form.bookPrice = this.form.bookPrice / 100
        this.form.salesPrice = this.form.salesPrice / 100
        this.form.marketPrice = this.form.marketPrice / 100
        let itemAttrList = res.data.itemAttrList
        itemAttrList.forEach(element => {
          console.log(element.attrValueShow)
            if(element.attrKey == 'goodsStyle'){
              this.goodsStyle = element.attrValueShow
            }
            if(element.attrKey == 'goodsAgeGroup'){
              this.goodsAgeGroup = element.attrValueShow
            }
            if(element.attrKey == 'styleIndex'){
              this.styleIndex = element.attrValueShow
            }
            if(element.attrKey == 'clothingCategory'){
              this.clothingCategory = element.attrValueShow
            }
            if(element.attrKey == 'assistComposition'){
              this.assistComposition = element.attrValueShow
            }
            if(element.attrKey == 'fabricComposition'){
              this.fabricComposition = element.attrValueShow
            }
        });
      })
          
    }
  }
}
</script>
<style scoped lang="scss">
  .dis{
    width: 100%;
    height: 50px;
    text-align:center
  }
</style>