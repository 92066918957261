<template>
    <div class="crumbs clear">
        <div class="icon" @click="goHome" title="首页"></div>
        <div class="seat" v-for="(item,index) in crumbs" :key="index">
            <router-link :to="{path:item.path}" :class="index==(crumbs.length-1)?'blue':''">{{ item.text }}</router-link> <i class="el-icon-close" title="关闭" style="cursor: pointer;" @click="goBackPage(index)" v-show="index==(crumbs.length-1)&&crumbs.length>2"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'crumbs',
        props: ['crumbs'],
        data(){
            return {

            }
        },
        methods:{
            goHome(){
                this.$router.push('/home')
            },
            goBackPage(index){
                this.$router.push(this.crumbs[index-1])
            }
        },
    }
</script>

<style scoped lang="scss">
    .crumbs{
        width: 100%;
        height: 40px;
        background: #fff;
        line-height: 40px;
        font-size: 12px;
        color: #1A1A1A;
    }

    .icon{
        width: 16px;
        height: 16px;
        background: url(~@/assets/images/home.png) no-repeat center/100%;
        float: left;
        margin-left: 12px;
        margin-right: 8px;
        margin-top: 10px;
        cursor: pointer;
    }

    .seat{
        float: left;
        &::after{
            content: '>';
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
        }
        &:last-child::after{
            content: '';
        }
        a:hover{
            color: #0198E3;
        }
    }
    .blue{
        color:#0198E3;
    }

</style>
